<template>
  <b-form>
    <b-form-group
      label="Valor Total"
      :label-for="field"
      :class="validationClass"
    >
      <b-input-group prepend="R$">
        <b-form-input
          :id="field"
          v-model="form[field]"
          v-money="{
            decimal: ',',
            thousands: '.',
            precision: 2
          }"
          :state="validation[field]"
        />
      </b-input-group>
      <b-form-invalid-feedback v-if="$v.form[field].$invalid">
        Informe o valor total
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      label="Quantidade de Produtos"
      :label-for="productField"
      :class="productValidationClass"
    >
      <b-input-group>
        <b-form-input
          :id="productField"
          v-model="form[productField]"
          v-money="{
            decimal: ',',
            thousands: '.',
            precision: 0
          }"
          :state="validation[productField]"
        />
      </b-input-group>
      <b-form-invalid-feedback v-if="$v.form[productField].$invalid">
        Informe a quantidade total de produtos
      </b-form-invalid-feedback>
    </b-form-group>
  </b-form>
</template>

<script>
import { CHECKOUT_OPERATIONS } from '@/constants'
import withFormValidation from '@/mixins/withFormValidation'
import { formatCurrency, parseCurrency } from '@/helpers/numbers'

export default {
  name: 'CheckoutForm',
  filters: { formatCurrency },
  mixins: [
    withFormValidation({
      fields: [
        'openingValue',
        'openingProductCount',
        'closingValue',
        'closingProductCount'
      ]
    })
  ],
  validations: {
    form: {
      openingValue: {
        required(value) {
          return (
            !this.isOpeningCheckout ||
            (this.isOpeningCheckout && parseCurrency(value) > 0)
          )
        }
      },
      closingValue: {
        required(value) {
          return (
            !this.isClosingCheckout ||
            (this.isClosingCheckout && parseCurrency(value) > 0)
          )
        }
      },
      openingProductCount: {
        required(value) {
          return (
            !this.isOpeningCheckout ||
            (this.isOpeningCheckout && Number(value) >= 0)
          )
        }
      },
      closingProductCount: {
        required(value) {
          return (
            !this.isClosingCheckout ||
            (this.isClosingCheckout && Number(value) >= 0)
          )
        }
      }
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    operation: {
      type: String,
      required: true
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    isOpeningCheckout() {
      return this.operation === CHECKOUT_OPERATIONS.OPEN
    },
    isClosingCheckout() {
      return this.operation === CHECKOUT_OPERATIONS.CLOSE
    },
    field() {
      return this.isOpeningCheckout ? 'openingValue' : 'closingValue'
    },
    productField() {
      return this.isOpeningCheckout
        ? 'openingProductCount'
        : 'closingProductCount'
    },
    validationClass() {
      const { $dirty, $invalid } = this.$v.form[this.field]

      return {
        'is-invalid': $dirty && $invalid
      }
    },
    productValidationClass() {
      const { $dirty, $invalid } = this.$v.form[this.productField]

      return {
        'is-invalid': $dirty && $invalid
      }
    }
  },
  watch: {
    form: {
      handler(value) {
        this.$emit('input', value)
      },
      deep: true
    }
  },
  destroyed() {
    this.form = {
      openingValue: null,
      openingProductCount: null,
      closingValue: null,
      closingProductCount: null
    }
  }
}
</script>
