<!-- eslint-disable vue/no-template-shadow -->
<template>
  <b-modal v-model="open" :title="title" size="xl">
    <b-alert
      v-if="operations.data.length === 0"
      show
      variant="secondary"
      class="text-center"
    >
      Nenhum lançamento realizado para este caixa
    </b-alert>
    <b-table
      v-else
      ref="operationsList"
      responsive=""
      :fields="fields"
      :items="operations.data"
      striped
      hover
      show-empty
      :busy="operations.loading"
    >
      <template #cell(name)="{ value: name }">
        {{ OPERATION_NAMES_LABEL[name] || name }}
      </template>
      <template #cell(amount)="{ value: amount }">
        {{ amount | formatCurrency }}
      </template>
      <template #cell(type)="{ value: type }">
        {{ OPERATION_TYPES_LABELS[type] }}
      </template>
      <template #cell(createdAt)="{ value: createdAt }">
        {{ createdAt | formatDateTime }}
      </template>
    </b-table>

    <template #modal-footer="{ close }">
      <b-button variant="outline-secondary" @click="close"> Fechar </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  OPERATION_TYPES,
  OPERATION_TYPES_LABELS,
  OPERATION_NAMES_LABEL
} from '@/constants'
import withAsyncAction from '@/mixins/withAsyncAction'
import services from '@/services'
import { formatDateTime } from '@/helpers/date'
import { formatCurrency } from '@/helpers/numbers'

export default {
  name: 'OperationsModal',
  filters: {
    formatDateTime,
    formatCurrency
  },
  mixins: [
    withAsyncAction({
      key: 'operations',
      initialState: [],
      fetcher: {
        methodName: 'fetchOperations',
        handler: services.operations.fetchOperations
      }
    })
  ],
  props: {
    value: {
      type: Boolean
    },
    type: {
      type: Array,
      default: () => []
    },
    checkout: {
      type: Object,
      required: true
    },
    date: {
      type: String,
      default: null
    }
  },
  computed: {
    open: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    title() {
      if (this.type.length > 1) {
        return 'Lançamentos'
      }

      return this.type.includes(OPERATION_TYPES.REVENUE) ? 'Entradas' : 'Saídas'
    },
    fields() {
      return [
        {
          key: 'name',
          label: 'Descrição'
        },
        {
          key: 'amount',
          label: 'Valor'
        },
        {
          key: 'type',
          label: 'Tipo'
        },
        {
          key: 'createdAt',
          label: 'Lançado em'
        }
      ]
    }
  },
  watch: {
    async open(value) {
      if (value) {
        const {
          checkout: { id },
          type
        } = this

        const isOnlyRevenue =
          type.length === 1 && type.includes(OPERATION_TYPES.REVENUE)

        await this.fetchOperations({
          checkoutId: id,
          type,
          ...(isOnlyRevenue && { paymentId: null })
        })
      }
    }
  },
  created() {
    this.OPERATION_TYPES_LABELS = OPERATION_TYPES_LABELS
    this.OPERATION_NAMES_LABEL = OPERATION_NAMES_LABEL
  }
}
</script>
