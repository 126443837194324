var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":_vm.title,"size":"xl"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var close = ref.close;
return [_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":close}},[_vm._v(" Fechar ")])]}}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[(_vm.operations.data.length === 0)?_c('b-alert',{staticClass:"text-center",attrs:{"show":"","variant":"secondary"}},[_vm._v(" Nenhum lançamento realizado para este caixa ")]):_c('b-table',{ref:"operationsList",attrs:{"responsive":"","fields":_vm.fields,"items":_vm.operations.data,"striped":"","hover":"","show-empty":"","busy":_vm.operations.loading},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var name = ref.value;
return [_vm._v(" "+_vm._s(_vm.OPERATION_NAMES_LABEL[name] || name)+" ")]}},{key:"cell(amount)",fn:function(ref){
var amount = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(amount))+" ")]}},{key:"cell(type)",fn:function(ref){
var type = ref.value;
return [_vm._v(" "+_vm._s(_vm.OPERATION_TYPES_LABELS[type])+" ")]}},{key:"cell(createdAt)",fn:function(ref){
var createdAt = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(createdAt))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }