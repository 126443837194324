import { PAYMENT_METHODS_IDS } from '@/constants'
import reduce from 'lodash/fp/reduce'
import { parseCurrency } from './numbers'

const calculateTotal = (values) =>
  reduce.convert({ cap: false })(
    (acc, amount, quantity) => (acc += parseFloat(amount) * quantity),
    0,
    values
  )

const formatCheckout = (checkout) => ({
  ...checkout,
  openingValues: formatValues(checkout.openingValues),
  closingValues: formatValues(checkout.closingValues)
})

const formatValues = (values) =>
  reduce(
    (acc, current) => ({
      ...acc,
      [current.amount]: parseFloat(current.quantity)
    }),
    {},
    values
  )

const parseCheckout = ({
  openingValue,
  closingValue,
  openingProductCount,
  closingProductCount,
  ...checkout
}) => ({
  ...checkout,
  openingValue: parseCurrency(openingValue),
  openingProductCount: Number(openingProductCount),
  closingValue: parseCurrency(closingValue),
  closingProductCount: closingProductCount ? Number(closingProductCount) : null
})

const parseOperation = ({ amount, ...operation }) => ({
  ...operation,
  amount: parseCurrency(amount)
})

const calculateCheckoutTotal = (
  checkout,
  includeOpeningValue = false,
  paymentMethods = Object.values(PAYMENT_METHODS_IDS)
) => {
  const {
    openingValue,
    report: { expense, revenue, rents, products }
  } = checkout

  const productsTotal = reduce.convert({ cap: false })(
    (result, current, key) =>
      paymentMethods.includes(key)
        ? (result += parseFloat(current) || 0)
        : result,
    0,
    products
  )

  const rentsTotal = reduce.convert({ cap: false })(
    (result, current, key) =>
      paymentMethods.includes(key)
        ? (result += parseFloat(current) || 0)
        : result,
    0,
    rents
  )

  const total =
    parseFloat(revenue || 0) +
    parseFloat(productsTotal || 0) +
    parseFloat(rentsTotal || 0) -
    parseFloat(expense || 0)

  return includeOpeningValue ? total + parseFloat(openingValue || 0) : total
}

export {
  calculateTotal,
  formatCheckout,
  parseCheckout,
  parseOperation,
  calculateCheckoutTotal
}
