<template>
  <b-form novalidate @submit.prevent="handleSubmit">
    <b-modal
      v-model="open"
      :title="title"
      @ok.prevent="onOk"
      @cancel="onCancel"
      @close="onCancel"
    >
      <CheckoutForm
        ref="form"
        v-model="form"
        :operation="operation"
        @submit="onSubmit"
      />
    </b-modal>
  </b-form>
</template>

<script>
import CheckoutForm from './CheckoutForm'

export default {
  name: 'CheckoutModal',
  components: {
    CheckoutForm
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    value: {
      type: Boolean
    },
    title: {
      type: String,
      default: ''
    },
    operation: {
      type: String,
      required: true
    },
    checkout: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      form: {
        openingValue: null,
        openingProductCount: null,
        closingValue: null,
        closingProductCount: null
      }
    }
  },
  computed: {
    open: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  watch: {
    checkout(value) {
      this.form = value
    }
  },
  methods: {
    onOk() {
      if (this.checkout == null) {
        if (this.items.some(({ closedAt }) => !closedAt)) {
          this.$root.$toast.error(
            'Feche o caixa do dia anterios antes de abrir outro!'
          )
          return
        }
      }
      this.$refs.form.handleSubmit()
    },
    onCancel() {
      this.$emit('cancel')
    },
    onSubmit() {
      this.$emit('ok', this.form)
    }
  }
}
</script>
