var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',[_c('b-form-group',{class:_vm.validationClass,attrs:{"label":"Valor Total","label-for":_vm.field}},[_c('b-input-group',{attrs:{"prepend":"R$"}},[_c('b-form-input',{directives:[{name:"money",rawName:"v-money",value:({
          decimal: ',',
          thousands: '.',
          precision: 2
        }),expression:"{\n          decimal: ',',\n          thousands: '.',\n          precision: 2\n        }"}],attrs:{"id":_vm.field,"state":_vm.validation[_vm.field]},model:{value:(_vm.form[_vm.field]),callback:function ($$v) {_vm.$set(_vm.form, _vm.field, $$v)},expression:"form[field]"}})],1),(_vm.$v.form[_vm.field].$invalid)?_c('b-form-invalid-feedback',[_vm._v(" Informe o valor total ")]):_vm._e()],1),_c('b-form-group',{class:_vm.productValidationClass,attrs:{"label":"Quantidade de Produtos","label-for":_vm.productField}},[_c('b-input-group',[_c('b-form-input',{directives:[{name:"money",rawName:"v-money",value:({
          decimal: ',',
          thousands: '.',
          precision: 0
        }),expression:"{\n          decimal: ',',\n          thousands: '.',\n          precision: 0\n        }"}],attrs:{"id":_vm.productField,"state":_vm.validation[_vm.productField]},model:{value:(_vm.form[_vm.productField]),callback:function ($$v) {_vm.$set(_vm.form, _vm.productField, $$v)},expression:"form[productField]"}})],1),(_vm.$v.form[_vm.productField].$invalid)?_c('b-form-invalid-feedback',[_vm._v(" Informe a quantidade total de produtos ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }